<template>
  <div :id="this.plansV2_cont" class="plans-v2 pt-5 text-center">
    <div class="mt-80">
      <div>
        <h3 class="font-weight-normal">
          Estos son los Planes
          <span class="text-primary font-weight-bold">PetDr. Plan</span>
          Disponibles <br />
          para tus mascotas.
        </h3>
      </div>
      <div class="text-center text-secondary mt-5">
        <h4>Nombre de la mascota</h4>
      </div>
    </div>

    <b-modal
      id="details-modal"
      centered
      hide-header
      hide-footer
      size="lg"
      body-class="py-0"
    >
      <b-row v-if="selectPlan.name == 'Supreme plus'">
        <b-col lg="4" class="px-0">
          <b-list-group flush>
            <b-list-group-item class="text-center">
              <h4 class="font-weight-bold mb-0">Lo que debes saber</h4>
            </b-list-group-item>
            <b-list-group-item
              href="javascript:;"
              class="border-0"
              v-for="(group, i) in groups"
              v-bind:active="group.name === selectedGroup.name"
              v-bind:key="i"
              v-on:click="() => openDetailsModal(group, selectPlan)"
            >
              <h6 class="font-weight-light mb-0">{{ group.label }}</h6>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col lg="8" class="px-0 border-left">
          <b-list-group flush>
            <b-list-group-item class="border-bottom">
              <h4 class="font-weight-bold mb-0">{{ selectedGroup.label }}</h4>
            </b-list-group-item>
          </b-list-group>
          <div
            class="text-muted p-4"
            v-if="selectedGroup.label === 'Coberturas'"
          >
            <p>
              En este apartado encontarar todas las coberturas que te ofrece
              nuestro plan
              <span class="font-weight-light text-primary">Supreme plus</span>
            </p>
            <div
              class=""
              v-for="(desc, idx) in this.selectPlan.description"
              v-bind:key="idx"
            >
              <span class="font-weight-normal text-secondary">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-check float-left mt-2 text-primary"
                  >
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
                <div class="ml-5">{{ desc }}</div></span
              >
            </div>
          </div>
          <div
            class="text-muted p-4"
            v-else-if="selectedGroup.label === 'Beneficios'"
          >
            <p>
              En este apartado encontarar todos los Beneficios que te ofrece
              nuestro plan
              <span class="font-weight-light text-primary">Supreme plus</span>
            </p>
            <div
              class=""
              v-for="(desc, idx) in this.selectPlan.description"
              v-bind:key="idx"
            >
              <span class="font-weight-normal text-secondary">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-check float-left mt-2 text-primary"
                  >
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
                <div class="ml-5">{{ desc }}</div></span
              >
            </div>
          </div>
          <div
            class="text-muted p-4 text-center"
            v-else-if="selectedGroup.label === 'Periodos de espera'"
          >
            <h1>Proximamente</h1>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="selectPlan.name == 'Supreme pet'">
        <b-col lg="4" class="px-0">
          <b-list-group flush>
            <b-list-group-item class="text-center">
              <h4 class="font-weight-bold mb-0">Lo que debes saber</h4>
            </b-list-group-item>
            <b-list-group-item
              href="javascript:;"
              class="border-0"
              v-for="(group, i) in groups"
              v-bind:active="group.name === selectedGroup.name"
              v-bind:key="i"
              v-on:click="() => openDetailsModal(group, selectPlan)"
            >
              <h6 class="font-weight-light mb-0">{{ group.label }}</h6>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col lg="8" class="px-0 border-left">
          <b-list-group flush>
            <b-list-group-item class="border-bottom">
              <h4 class="font-weight-bold mb-0">{{ selectedGroup.label }}</h4>
            </b-list-group-item>
          </b-list-group>
          <div
            class="text-muted p-4"
            v-if="selectedGroup.label === 'Coberturas'"
          >
            <p>
              En este apartado encontarar todas las coberturas que te ofrece
              nuestro plan
              <span class="font-weight-light text-primary">Supreme pet</span>
            </p>
            <div
              class=""
              v-for="(desc, idx) in this.selectPlan.description"
              v-bind:key="idx"
            >
              <span class="font-weight-normal text-secondary">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-check float-left mt-2 text-primary"
                  >
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
                <div class="ml-5">{{ desc }}</div></span
              >
            </div>
          </div>
          <div
            class="text-muted p-4"
            v-else-if="selectedGroup.label === 'Beneficios'"
          >
            <p>
              En este apartado encontarar todos los Beneficios que te ofrece
              nuestro plan
              <span class="font-weight-light text-primary">Supreme pet</span>
            </p>
            <div
              class=""
              v-for="(desc, idx) in this.selectPlan.description"
              v-bind:key="idx"
            >
              <span class="font-weight-normal text-secondary">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-check float-left mt-2 text-primary"
                  >
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
                <div class="ml-5">{{ desc }}</div></span
              >
            </div>
          </div>
          <div
            class="text-muted p-4 text-center"
            v-else-if="selectedGroup.label === 'Periodos de espera'"
          >
            <h1>Proximamente</h1>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="selectPlan.name == 'Optimum pet'">
        <b-col lg="4" class="px-0">
          <b-list-group flush>
            <b-list-group-item class="text-center">
              <h4 class="font-weight-bold mb-0">Lo que debes saber</h4>
            </b-list-group-item>
            <b-list-group-item
              href="javascript:;"
              class="border-0"
              v-for="(group, i) in groups"
              v-bind:active="group.name === selectedGroup.name"
              v-bind:key="i"
              v-on:click="() => openDetailsModal(group, selectPlan)"
            >
              <h6 class="font-weight-light mb-0">{{ group.label }}</h6>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col lg="8" class="px-0 border-left">
          <b-list-group flush>
            <b-list-group-item class="border-bottom">
              <h4 class="font-weight-bold mb-0">{{ selectedGroup.label }}</h4>
            </b-list-group-item>
          </b-list-group>
          <div
            class="text-muted p-4"
            v-if="selectedGroup.label === 'Coberturas'"
          >
            <p>
              En este apartado encontarar todas las coberturas que te ofrece
              nuestro plan
              <span class="font-weight-light text-primary">Optimum pet</span>
            </p>
            <div
              class=""
              v-for="(desc, idx) in this.selectPlan.description"
              v-bind:key="idx"
            >
              <span class="font-weight-normal text-secondary">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-check float-left mt-2 text-primary"
                  >
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
                <div class="ml-5">{{ desc }}</div></span
              >
            </div>
          </div>
          <div
            class="text-muted p-4"
            v-else-if="selectedGroup.label === 'Beneficios'"
          >
            <p>
              En este apartado encontarar todos los Beneficios que te ofrece
              nuestro plan
              <span class="font-weight-light text-primary">Optimum pet</span>
            </p>
            <div
              class=""
              v-for="(desc, idx) in this.selectPlan.description"
              v-bind:key="idx"
            >
              <span class="font-weight-normal text-secondary">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-check float-left mt-2 text-primary"
                  >
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
                <div class="ml-5">{{ desc }}</div></span
              >
            </div>
          </div>
          <div
            class="text-muted p-4 text-center"
            v-else-if="selectedGroup.label === 'Periodos de espera'"
          >
            <h1>Proximamente</h1>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="selectPlan.name == 'Essential pet'">
        <b-col lg="4" class="px-0">
          <b-list-group flush>
            <b-list-group-item class="text-center">
              <h4 class="font-weight-bold mb-0">Lo que debes saber</h4>
            </b-list-group-item>
            <b-list-group-item
              href="javascript:;"
              class="border-0"
              v-for="(group, i) in groups"
              v-bind:active="group.name === selectedGroup.name"
              v-bind:key="i"
              v-on:click="() => openDetailsModal(group, selectPlan)"
            >
              <h6 class="font-weight-light mb-0">{{ group.label }}</h6>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col lg="8" class="px-0 border-left">
          <b-list-group flush>
            <b-list-group-item class="border-bottom">
              <h4 class="font-weight-bold mb-0">{{ selectedGroup.label }}</h4>
            </b-list-group-item>
          </b-list-group>
          <div
            class="text-muted p-4"
            v-if="selectedGroup.label === 'Coberturas'"
          >
            <p>
              En este apartado encontarar todas las coberturas que te ofrece
              nuestro plan
              <span class="font-weight-light text-primary">Essential pet</span>
            </p>
            <div
              class=""
              v-for="(desc, idx) in this.selectPlan.description"
              v-bind:key="idx"
            >
              <span class="font-weight-normal text-secondary">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-check float-left mt-2 text-primary"
                  >
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
                <div class="ml-5">{{ desc }}</div></span
              >
            </div>
          </div>
          <div
            class="text-muted p-4"
            v-else-if="selectedGroup.label === 'Beneficios'"
          >
            <p>
              En este apartado encontarar todos los Beneficios que te ofrece
              nuestro plan
              <span class="font-weight-light text-primary">Essential pet</span>
            </p>
            <div
              class=""
              v-for="(desc, idx) in this.selectPlan.description"
              v-bind:key="idx"
            >
              <span class="font-weight-normal text-secondary">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-check float-left mt-2 text-primary"
                  >
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
                <div class="ml-5">{{ desc }}</div></span
              >
            </div>
          </div>
          <div
            class="text-muted p-4 text-center"
            v-else-if="selectedGroup.label === 'Periodos de espera'"
          >
            <h1>Proximamente</h1>
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <b-container class="py-5 plans-v2 mb-150">
      <b-row>
        <b-col>
          <carousel
            v-bind:per-page-custom="[
              [0, 1],
              [960, 2],
              [1400, 3],
            ]"
            v-bind:autoplay="true"
          >
            <slide v-for="plan in plans" v-bind:key="plan.id">
              <b-card class="rounded border-0 shadow-lg my-4 mx-4 pt-0" no-body>
                <div class="bg-primary rounded-top text-center">
                  <h3 class="text-white mb-0" v-text="plan.name"></h3>
                </div>
                <b-card-body class="pt-2">
                  <div class="text-center">
                    <b-badge
                      variant="primary"
                      class="px-3"
                      v-if="plan.recommended"
                      >RECOMENDADO</b-badge
                    >

                    <div
                      class="
                        d-flex
                        align-baseline-start
                        justify-content-center
                        pt-2
                      "
                    >
                      <h4 class="text-primary pt-2">$</h4>
                      <h1 class="text-primary">
                        {{ new Intl.NumberFormat("es-CO").format(plan.price) }}
                      </h1>
                      <h3 class="text-primary pt-2">{{ plan.currency }}</h3>
                    </div>
                    <h3 class="font-weight-light benefit-detail">
                      BENEFICIO ANUAL
                    </h3>

                    <b-list-group flush>
                      <b-list-group-item
                        v-for="(group, i) in groups"
                        v-bind:key="i"
                        href="javascript:;"
                        v-on:click="() => openDetailsModal(group, plan)"
                      >
                        {{ group.label }}
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </b-card-body>
              </b-card>
            </slide>
          </carousel>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import { mapGetters } from "vuex";
export default {
  components: {
    Carousel,
    Slide,
  },
  props: {
    props: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      selectedGroup: {},
      selectPlan: "",
      planName: "",
      plansV2_cont: "plansV2",
    };
  },
  computed: {
    plans() {
      return this.$store.state.plans.plans;
    },
    groups() {
      return (
        this.props.groups ?? [
          { name: "coverage", label: "Coberturas" },
          { name: "benefits", label: "Beneficios" },
          { name: "waiting_periods", label: "Periodos de espera" },
        ]
      );
    },
    ...mapGetters({
      logo: "system/logo",
    }),
  },
  methods: {
    openDetailsModal(group, planObj) {
      this.selectedGroup = group;
      this.$bvModal.show("details-modal");
      this.selectPlan = planObj;
    },
  },
};
</script>

<style scoped>
.benefit-detail {
  line-height: 1.75rem;
}
.list-group-item.active {
  background-color: var(--aff-primary);
}

.plans-v2 {
  border: 1px solid white;
}

.mb-150 {
  margin-bottom: 100px;
}
.mt-80 {
  margin-top: 80px;
}
</style>